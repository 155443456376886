<template>
  <div>
    <page-header name="Validação de Participantes"/>
    <b-card>
      <b-row>
        <b-col class="text-right">
          <b-dropdown
            id="dropdown-1"
            right
            variant="flat"
            class="dropdown-icon-wrapper"
          >
            <template #button-content>
              <feather-icon
                icon="AlignJustifyIcon"
                size="16"
                class="align-middle"
              />
            </template>
            <b-dropdown-item @click="onExport('xls')">
              <feather-icon
                icon="PrinterIcon"
                size="18"
              />
              Dowload XLS
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <Grid
        :fields="columns"
        :items="items"
        edit-button-label="edit"
        new-button-label="new"
        :show-search="false"
        @onChangePage="getParticipants"
      >
        <template v-slot:search>
          <b-row>
            <b-col sm="5" md="6">
              <i2-form-vue-select
                v-model="search.status"
                :multiple="true"
                label=""
                placeholder="Situação do cupom"
                :options="couponStatusList"
                name="couponStatus"
              />
            </b-col>
            <b-col sm="6" md="4">
              <b-button
                class="mr-1"
                variant="outline-primary"
                @click="$bvModal.show('modal-select2')"
              >
                <FeatherIcon icon="FilterIcon" />
                &nbsp;<span class="text-nowrap">Filtros</span>
              </b-button>
              <b-button
                variant="primary"
                class="mr-1"
                @click="getParticipants()"
              >
                <FeatherIcon icon="RotateCwIcon"/>
                <span class="ml-1 text-nowrap">Pesquisar</span>
              </b-button>
            </b-col>
          </b-row>

        </template>
        <template #cell(name)="data">
          {{ data.item.name }}<br/>
          <b>Documento:</b>
          <the-mask class="document-table" readonly disabled="true" :mask="['###.###.###-##', '##.###.###/####-##']"
                    :value="data.item.document"
          />
        </template>
        <template #cell(created_by)="data">
          {{ data.item.created_by ? data.item.created_by.name : '' }}
        </template>
        <template #cell(type)="data">
          <span v-if="data.item.coupons[0].type === 'coupon'">
            Cupom / Nota Fiscal
          </span>
          <span v-if="data.item.coupons[0].type === 'contract'">
            Contrato
          </span>
        </template>
        <template #cell(total_coupon)="data">
          <span v-if="data.item.coupons[0].type === 'coupon'">
            {{ data.item.coupons.length }}
          </span>
          <span v-if="data.item.coupons[0].type === 'contract'">
            -
          </span>
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.item.status === 'new'" variant="primary">
            Novo
          </b-badge>
          <b-badge v-if="data.item.status === 'approved'" variant="success">
            Aprovado
          </b-badge>
          <b-badge v-if="data.item.status === 'rejected'" variant="danger">
            Rejeitado
          </b-badge>
        </template>
        <template #cell(value)="data">
          {{ data.item.value_document |toCurrency }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-modal.modal-prevent-closing
            variant="gradient-primary"
            class="btn-icon"
            @click="showModal(data.item)"
          >
            <feather-icon icon="EyeIcon"/>
          </b-button>
        </template>
      </Grid>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="primary" @click="$router.push('/participants')">
            Voltar
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <coupon-detail :open="isShowModalCoupon" :current-participant="current_participant" @close="closeCouponModal"
                   @saved="onSaveCoupon"
    />
    <!-- Modal Filtros  -->
    <b-modal id="modal-select2"
             :no-close-on-backdrop="true"
             cancel-variant="outline-secondary"
             ok-title="Pesquisar"
             centered
             cancel-title="Fechar"
             title="Filtros"
             @ok="onclickSearchFilter"
    >
      <b-form>
        <b-row>
          <b-col sm="12">
            <i2-form-input
              id="filter-name"
              v-model="search.name"
              label="Nome"
              placeholder="Nome"
              type="text"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <i2-form-input
              id="filter-series"
              v-model="search.series"
              label="Série"
              placeholder="Série"
              type="text"
            />
          </b-col>
          <b-col sm="6">
            <i2-form-input
              id="filter-number"
              v-model="search.number"
              label="Número"
              placeholder="Número / Nota contrato"
              type="text"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <i2-form-input
              id="filter-document"
              v-model="search.document"
              v-maska
              label="CPF/CNPJ"
              rules="cpfCnpj"
              data-maska="['###.###.###-##','##.###.###/####-##']"
              placeholder="CPF/CNPJ"
              type="text"
            />
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import { getUserData } from '@/auth/utils'
import CouponStatus from '@/variables/CouponStatus'
import CouponDetail from '@/components/app/coupons/CouponDetail'
import { vMaska } from 'maska'

export default {
  name: 'ParticipantCouponValidate',
  components: {
    CouponDetail,
  },
  directives: { maska: vMaska },
  data() {
    return {
      search: {
        status: [CouponStatus.find(item => item.value === 'new')],
        document: '',
      },
      couponStatusList: CouponStatus,
      userData: null,
      items: [],
      per_page: 25,
      isShowModalCoupon: false,
      current_participant: {
        name: '',
        email: '',
        value_document: '',
        serie_coupon: '',
        number_coupon: '',
        file_url: '',
        status: '',
        coupons: [],
      },
      columns: [
        {
          key: 'name',
          label: 'Nome / Documento',
          class: 'w-20',
        },
        {
          key: 'created_by',
          label: 'Criado Por',
          class: 'w-10',
        },
        {
          key: 'created_at',
          label: 'envio',
          class: 'w-10',
        },
        {
          key: 'value',
          label: 'Valor (R$)',
          class: 'w-10',
        },
        {
          key: 'type',
          label: 'Tipo',
          class: 'w-10',
        },
        {
          key: 'total_coupon',
          label: 'Total de Cupons',
          class: 'w-10 text-center',
        },
        {
          key: 'status',
          label: 'Situação',
          class: 'w-10',
        },
        {
          key: 'action',
          label: 'Ações',
          class: 'w-10 text-center',
        },
      ],
    }
  },
  mounted() {
    this.userData = getUserData()
    this.getParticipants()
  },
  methods: {
    onclickSearchFilter() {
      this.getParticipants()
    },
    async save(status, data) {
      const response = await this.$http.post(`coupons/${this.current_participant.uuid}/${status}`, data)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.nextParticipant()
    },
    approved(bvModal) {
      bvModal.preventDefault()
      this.save('approve', this.current_participant)
    },
    nextParticipant() {
      let nextParticipantIndex = null
      this.items.data.forEach((participant, index) => {
        if (this.current_participant.uuid === participant.uuid) {
          nextParticipantIndex = index
        }
      })
      nextParticipantIndex++
      if (this.items.data[nextParticipantIndex]) {
        this.current_participant = this.items.data[nextParticipantIndex]
        this.showModal(nextParticipantIndex)
      } else {
        this.isShowModalCoupon = false
      }
      this.getParticipants()
    },
    async getParticipants(page) {
      const data = { ...this.search }
      data.document = this.search?.document.replace(/\D/g, '')
      const route = `/promotions/${this.userData.current_promotion.uuid}/coupons`
      this.items = await this.$search.get(
        route,
        page,
        data,
        '',
      )
    },
    showModal(item) {
      this.isShowModalCoupon = true
      this.current_participant = item
    },
    async onExport() {
      const data = { ...this.search }
      data.document = this.search?.document.replace(/\D/g, '')
      const route = `/promotions/${this.userData.current_promotion.uuid}/coupons/export`
      const response = await this.$search.get(
        route,
        null,
        data,
        '',
      )
      this.$notify.success(response.message)
    },
    closeCouponModal() {
      this.isShowModalCoupon = false
    },
    onSaveCoupon() {
      this.isShowModalCoupon = false
      this.getParticipants(1)
    },
  },
}
</script>

<style scoped>
.document-table {
  border: none;
  background-color: transparent;
  color: #6e6b7b;
}
</style>
