const CouponStatus = [
  {
    value: 'new',
    text: 'Novos',
  },
  {
    value: 'approved',
    text: 'Aprovados',
  },
  {
    value: 'rejected',
    text: 'Rejeitados',
  },
]
export default CouponStatus
