const CouponStatusConstants = {
  new: {
    value: 'new',
    text: 'Novos',
  },
  approved: {
    value: 'approved',
    text: 'Aprovados',
  },
  rejected: {
    value: 'rejected',
    text: 'Rejeitados',
  },
}
export default CouponStatusConstants
