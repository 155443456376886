<template>
  <div>
    <b-modal
      id="validateModal"
      v-model="isOpen"
      centered
      ok-title="Salvar"
      cancel-title="Cancelar"
      no-close-on-backdrop
      :ok-disabled="isRejected()"
      @hide="onCancel"
      @ok="onSave"
      @cancel="onCancel"
    >
      <b-row>
        <b-col class="text-center">
          <b>
            {{ actualCouponData.type === 'coupon' ? 'Cupom' : 'Contrato' }}
          </b> {{ couponRead }}/ {{ totalCoupons }} <br>
          <b-badge :variant="getBadgeVariant()" class="d-block">
            {{ actualCouponData.status === CouponStatusConstants.approved.value ? 'Aprovado' : 'Rejeitado' }}
            <span v-if="actualCouponData.rejected_by !== ''">
              <br>
              {{ actualCouponData.rejected_by }}
            </span>
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1 mb-1 text-center">
        <b-col>
          <b-button-group size="sm">
            <b-button
              variant="outline-primary"
              @click="previousCoupon()"
            >
              Anterior
            </b-button>
            <b-button
              :variant="getClassName()"
              :disabled="isRejected()"
              @click="changeStatus()"
            >
              {{ getLabel() }}
            </b-button>
            <b-button
              variant="outline-primary"
              @click="nextCoupon()"
            >
              Próximo
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-bold mb-1" md="3" sm="3">
          Incluído por:
        </b-col>
        <b-col md="9" sm="9">
          {{ actualCouponData.created_by ? actualCouponData.created_by.name : '' }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-bold" md="3" sm="3">
          Nome:
        </b-col>
        <b-col md="9" sm="9">
          {{ actualCouponData.name }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-bold" md="3" sm="3">
          Documento:
        </b-col>
        <b-col md="9" sm="9">
          {{ actualCouponData.document }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-bold" md="3" sm="3">
          Série:
        </b-col>
        <b-col md="9" sm="9">
          {{ actualCouponData.serie_coupon }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-bold" md="3" sm="3">
          Número:
        </b-col>
        <b-col md="9" sm="9">
          {{ actualCouponData.number_coupon }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-bold" md="3" sm="3">
          Valor:
        </b-col>
        <b-col md="9" sm="9">
          {{ actualCouponData.value_document | toCurrency }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-bold" md="3" sm="3">
          Data:
        </b-col>
        <b-col md="9" sm="9">
          {{ actualCouponData.date }}
        </b-col>
      </b-row>
      <b-row v-if="getFileType() !== 'pdf' && actualCouponData.qrcode_url !== ''">
        <b-col class="text-bold" md="3" sm="3">
          Url Nota:
        </b-col>
        <b-col md="9" sm="9">
          <a :href="actualCouponData.qrcode_url" target="_blank">Visualizar</a>
        </b-col>

      </b-row>
      <i2-divider/>
      <b-row>
        <b-col>
          <b-carousel
            v-if="getFileType() !== 'pdf'"
            id="carousel-fade"
            indicators
            controls
          >
            <b-carousel-slide v-for="(image, index) of actualCouponData.images" :key="index">

              <template #img>
                <b-img
                  class="img-fluid w-100 d-block"
                  :src="image.url"
                />
                <div class="text-center">
                  <a :href="image.url" target="_blank">Ver Arquivo</a>
                </div>
              </template>
            </b-carousel-slide>
          </b-carousel>
          <div v-if="getFileType() === 'pdf'" class="text-center">
            <a :href="actualCouponData.images[0].url" target="_blank">Ver Arquivo PDF</a>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <!-- modal para rejeição -->
    <b-modal
      id="rejectedModal"
      v-model="isShowModalRejected"
      centered
      title="Selecione o motivo da reprovação:"
      ok-title="Reprovar"
      no-close-on-backdrop
      ok-variant="danger"
      cancel-title="Cancelar"
      @ok="onReject"
    >
      <b-form>
        <b-form-radio-group stacked>
          <b-form-radio
            v-for="(option, index) in rejectedOptions"
            :key="index"
            v-model="rejectedBy"
            :value="option.text"
            :name="option.name"
          >
            {{ option.text }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import I2Divider from '@/components/i2/content/i2Divider'
import {
  BButtonGroup, BCarousel, BCarouselSlide, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'

import CouponStatusConstants from '@/variables/CouponStatusConstants'

export default {
  name: 'CouponDetail',
  components: {
    I2Divider,
    BCarousel,
    BCarouselSlide,
    BButtonGroup,
    BFormRadio,
    BFormRadioGroup,
  },
  props: {
    open: {
      type: Boolean,
      require: true,
    },
    currentParticipant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      isShowModalRejected: false,
      rejectedBy: false,
      totalCoupons: 0,
      couponRead: 1,
      actualIndexCoupon: 0,
      actualCouponData: { status: CouponStatusConstants.approved.value },
      rejectedOptions: [
        {
          name: 'valor_informado_diferente_da_nota',
          text: 'Valor informado diferente da nota',
        },
        {
          name: 'imagem_em_baixa_qualidade',
          text: 'Imagem em baixa qualidade',
        },
        {
          name: 'empresa_nao_participante',
          text: 'Empresa não participante',
        },
        {
          name: 'imagem_invalida',
          text: 'Imagem inválida',
        },
        {
          name: 'fora_do_periodo_de_participacao',
          text: 'Fora do período de participação',
        },
      ],
    }
  },
  computed: {
    CouponStatusConstants() {
      return CouponStatusConstants
    },
  },
  watch: {
    open(value) {
      this.isOpen = value
    },
    currentParticipant(value) {
      if (!value) {
        return
      }
      this.currentParticipant.coupons.map(coupon => {
        if (coupon.status === 'new') {
          coupon.status = 'approved'
          coupon.rejected_by = ''
        }
        return coupon
      })
      this.totalCoupons = this.currentParticipant.coupons.length
      this.actualCouponData = this.currentParticipant.coupons[0]
    },
  },
  methods: {
    isRejected() {
      return this.currentParticipant.user !== null || this.currentParticipant.status === CouponStatusConstants.rejected.value
    },
    previousCoupon() {
      if (this.actualIndexCoupon !== 0) {
        this.actualIndexCoupon--
        this.showCoupon()
      }
    },
    nextCoupon() {
      if (this.actualIndexCoupon !== (this.totalCoupons - 1)) {
        this.actualIndexCoupon++
        this.showCoupon()
      }
    },
    showCoupon() {
      this.couponRead = (this.actualIndexCoupon + 1)
      this.actualCouponData = this.currentParticipant.coupons[this.actualIndexCoupon]
    },
    changeStatus() {
      if (this.actualCouponData.status === CouponStatusConstants.approved.value) {
        this.isShowModalRejected = true
      } else {
        this.actualCouponData.status = CouponStatusConstants.approved.value
        this.currentParticipant.coupons[this.actualIndexCoupon].status = CouponStatusConstants.approved.value
        this.currentParticipant.coupons[this.actualIndexCoupon].rejected_by = ''
      }
    },
    getLabel() {
      return this.actualCouponData.status === CouponStatusConstants.approved.value ? 'Rejeitar' : 'Aprovar'
    },
    getClassName() {
      if (this.actualCouponData.status === CouponStatusConstants.approved.value) {
        return 'outline-danger'
      }
      return 'outline-success'
    },
    getBadgeVariant() {
      return this.actualCouponData.status === CouponStatusConstants.approved.value ? 'success' : 'danger'
    },
    onReject() {
      this.actualCouponData.status = CouponStatusConstants.rejected.value
      this.currentParticipant.coupons[this.actualIndexCoupon].status = CouponStatusConstants.rejected.value
      this.currentParticipant.coupons[this.actualIndexCoupon].rejected_by = this.rejectedBy
    },
    onSave(bvModal) {
      bvModal.preventDefault()
      let showConfirmModal = false
      this.currentParticipant.coupons.forEach(dataCoupon => {
        if (dataCoupon.status === CouponStatusConstants.rejected.value) {
          showConfirmModal = true
        }
      })

      if (showConfirmModal) {
        let message = 'Um ou mais cupom estão marcados como rejeitados, deste modo todo o lote será rejeitado. Confirma esta operação?'
        this.$bvModal.msgBoxConfirm(message, {
          title: 'Atenção!',
          size: 'md',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          noCloseOnBackdrop: true,
        })
          .then(response => {
            if (response) {
              this.save()
            } else {
              this.isOpen = true
            }
          })
        return
      }
      this.save()
    },
    async save() {
      const data = {
        uuid: this.currentParticipant.uuid,
        coupons: this.currentParticipant.coupons,
      }
      await this.$http.post(`/coupons/${data.uuid}/approve`, data)
        .then(response => {
          let message = 'Cupom aprovado com sucesso.'
          if (response.status === CouponStatusConstants.rejected.value) {
            message = 'Cupom reprovado com sucesso.'
          }
          this.$notify.success(message)
          this.$emit('saved', true)
        })
    },
    onCancel() {
      this.$emit('close', false)
    },
    getFileType() {
      const couponFirst = this.actualCouponData.file_name || ''
      return couponFirst.substring(couponFirst.length - 3)
        .toLowerCase()
        .trim()
    },
  },
}
</script>

<style scoped>
.text-bold {
  font-weight: bold;
}

.img-coupon {
  object-fit: contain;
  width: 100%;
  height: 500px;
}
</style>
